import React from "react";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import ExtendedTitle from "../components/extended-title";
import { graphql } from "gatsby";
import { Seo } from "../components/seo";
import Heading from "../components/heading";
import Marquee from "react-fast-marquee";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Vacancy({ data }) {
	const page = data.directus.vacancies[0];

	return (
		<Layout>
			<ExtendedTitle
				image={page.hero}
				backButtonTitle="Naar alle vacatures"
				backButtonTo="/werken-bij"
				subtitle={`${page.hours} in Oss`}
			>
				{page.title}
			</ExtendedTitle>
			<section className="bg-off-white border-t-2 border-gray pt-48 pb-16">
				<div className="container mx-auto">
					<article className="prose lg:prose-xl mx-auto">
						<ReactMarkdown children={page.content} />
					</article>
				</div>
			</section>

			<section className="bg-off-white pt-4">
				<Marquee gradient={false}>
					{data.directus.galleries.map((item, i) => (
						<div key={item.id} className="mx-3 w-80">
							<GatsbyImage
								className="object-cover rounded-xl"
								alt={item.id}
								image={getImage(item.image.imageFile)}
							/>
						</div>
					))}
				</Marquee>
			</section>

			<section className="bg-off-white text-xl">
				<div className="max-w-5xl px-8 pt-24 mx-auto">
					<Heading as="h2" className="mb-8">
						Over We Make It Spark & Ambassade32
					</Heading>
					<p className="mb-8">
						We Make It Spark is een full-service fotografiebureau,
						en daar valt sinds 2024 Ambassade32 ook onder. Op ons
						kantoor in Oss denken we na over het beeld van morgen.
						Dat mogen we doen voor fantastische opdrachtgevers (al
						zeggen zij het zelf). Het resultaat mag er zijn, want
						dat wordt bekeken door miljoenen kijkers.
					</p>
					<p className="mb-8">
						We komen ons bed uit voor fantastisch beeld, maar dat
						maakt iedereen. We onderscheiden ons door de optimale
						samenwerking tussen opdrachtgever en resultaat. A en Z
						zijn duidelijk, wij zoeken de rest van het alfabet
						erbij.
					</p>
					<p>
						In een klein team heeft iedereen veel te zeggen en heb
						je veel verantwoordelijkheid. Project- en
						accountmanagers bestaan bij ons niet. Jouw stem telt.
						Onze dagen zijn soms hectisch, en soms gewoon gezellig.
						Vaak weten we pas op zondag wat we die week hebben
						gedaan (of moesten doen). Maar die verrassing elke week
						maakt ons werk dan ook zo leuk.
					</p>
				</div>
			</section>
			<section className="bg-off-white text-xl">
				<div className="max-w-5xl px-8 py-24 mx-auto">
					<Heading as="h2" className="mb-8">
						Solliciteren
					</Heading>
					<p className="mb-8">
						We komen graag met jou in contact! Wil je meer weten
						over de functie, of wil je graag solliciteren? Mail dan
						naar vacature@wemakeitspark.nl.
					</p>
					<p>
						Bij je sollicitatie ontvangen we graag je motivatiebrief
						en eerder gemaakt werk.
					</p>
				</div>
			</section>
		</Layout>
	);
}

export const Head = ({ location, data }) => (
	<Seo
		pathname={location.pathname}
		pageTitle={data.directus.vacancies[0].title}
		title={`${data.directus.vacancies[0].title} - SPARK - fotografie voor cultuur, sport en entertainment`}
		description=""
	></Seo>
);

export const query = graphql`
	query VacancyQuery($slug: String) {
		directus {
			vacancies(filter: { slug: { _eq: $slug } }) {
				title
				hours
				hero {
					id
					imageFile {
						childImageSharp {
							gatsbyImageData(width: 1600, quality: 75)
						}
					}
				}
				content
			}
			galleries(filter: { location: { _contains: "vacancy" } }) {
				image {
					id
					imageFile {
						childImageSharp {
							gatsbyImageData(width: 600, quality: 75)
						}
					}
				}
				id
			}
		}
	}
`;
